import { getStore } from 'Store';

export const isRoStore = () => {
    const { ConfigReducer: { code } } = getStore().getState();

    return code === 'default';
}

export const isEuStore = () => {
    const { ConfigReducer: { code } } = getStore().getState();

    return code === 'en';
}

export const isItStore = () => {
    const { ConfigReducer: { code } } = getStore().getState();

    return code === 'it';
}
