/* eslint-disable import/prefer-default-export */
export const SLIDER = 'Slider';
export const NEW_PRODUCTS = 'NewProducts';
export const CATALOG_PRODUCT_LIST = 'CatalogProductList';
export const FARMEC_CATALOG_PRODUCT_LIST = 'FarmecCatalogProductList';
export const CATEGORY_WIDGET = 'CategoryWidget';
export const CMS_COUNTER = 'CmsCounter';
export const FREQUENTLY_BOUGHT_TOGETHER_WIDGET = 'FrequentlyBoughtTogetherWidget';
export const LOTTERY_PROMO_WIDGET = 'LotteryPromoWidget';


