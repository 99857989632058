import { BUNDLE, CONFIGURABLE, SIMPLE } from 'Util/Product';

export const checkEveryOption = (attributes, options) => Object.keys(options)
    .every((option) => {
        if (!attributes[option]) {
            return false;
        }

        const { attribute_value } = attributes[option];
        if (typeof options[option] === 'string') {
            return options[option] === attribute_value;
        }

        return options[option].includes(attribute_value);
    });

export const getIndexedAttributeOption = (option) => {
    const { swatch_data: defaultSwatchData } = option;
    if (!defaultSwatchData) {
        return option;
    }

    const { type } = defaultSwatchData;
    const swatch_data = type ? defaultSwatchData : null;

    return {
        ...option,
        swatch_data
    };
};

export const getIndexedAttributes = (attributes) => attributes.reduce((indexedAttributes, attribute) => {
    const { attribute_code, attribute_options = [] } = attribute;

    return {
        ...indexedAttributes,
        [attribute_code]: {
            ...attribute,
            attribute_options: attribute_options.reduce((acc, option) => {
                const { value } = option;

                return {
                    ...acc,
                    [value]: getIndexedAttributeOption(option)
                };
            }, {})
        }
    };
}, {});

export const getIndexedConfigurableOptions = (configurableOptions, indexedAttributes) => (
    configurableOptions.reduce((indexedConfigurableOptions, configurableOption) => {
        const { values, attribute_code } = configurableOption;

        return {
            ...indexedConfigurableOptions,
            [attribute_code]: {
                ...configurableOption,
                ...indexedAttributes[attribute_code],
                attribute_values: values.map(({ value_index }) => `${ value_index }`)
            }
        };
    }, {})
);

export const getIndexedVariants = (variants) => variants.map(({ product }) => {
    const { attributes } = product;
    return {
        ...product,
        attributes: getIndexedAttributes(attributes || [])
    };
});

/**
 * Get product variant index by options
 * @param {Object[]} variants
 * @param {{ attribute_code: string }[]} options
 * @returns {number}
 */
export const getVariantIndex = (variants, options) => variants
    .findIndex((variant) => checkEveryOption(variant.attributes, options));

export const getVariantsIndexes = (variants, options) => Object.entries(variants)
    .reduce((indexes, [index, variant]) => {
        if (checkEveryOption(variant.attributes, options)) {
            indexes.push(+index);
        }

        return indexes;
    }, []);

export const getIndexedCustomOption = (option) => {
    const {
        checkboxValues,
        dropdownValues,
        fieldValues,
        areaValues,
        ...otherFields
    } = option;

    if (checkboxValues) {
        return { type: 'checkbox', data: checkboxValues, ...otherFields };
    }

    if (dropdownValues) {
        return { type: 'dropdown', data: dropdownValues, ...otherFields };
    }

    if (fieldValues) {
        return { type: 'field', data: fieldValues, ...otherFields };
    }

    if (areaValues) {
        return { type: 'area', data: areaValues, ...otherFields };
    }

    // skip unsupported types
    return null;
};

export const getIndexedCustomOptions = (options) => options.reduce(
    (acc, option) => {
        const indexedOption = getIndexedCustomOption(option);

        if (indexedOption) {
            acc.push(indexedOption);
        }

        return acc;
    },
    []
);

export const getIndexedProduct = (product) => {
    const {
        variants: initialVariants = [],
        configurable_options: initialConfigurableOptions = [],
        attributes: initialAttributes = [],
        options: initialOptions = []
    } = product;

    const attributes = getIndexedAttributes(initialAttributes || []);

    return {
        ...product,
        configurable_options: getIndexedConfigurableOptions(initialConfigurableOptions, attributes),
        variants: getIndexedVariants(initialVariants),
        options: getIndexedCustomOptions(initialOptions || []),
        attributes
    };
};

export const getIndexedProducts = (products) => products.map(getIndexedProduct);

export const getIndexedParameteredProducts = (products) => Object.entries(products)
    .reduce((products, [id, product]) => ({
        ...products,
        [id]: getIndexedProduct(product)
    }), {});

export const getExtensionAttributes = (product) => {
    const {
        configurable_options,
        configurableVariantIndex,
        productOptions,
        productOptionsMulti,
        variants,
        type_id
    } = product;

    if (type_id === CONFIGURABLE) {
        const { attributes = {} } = variants[configurableVariantIndex] || {};

        const configurable_item_options = Object.values(configurable_options)
            .reduce((prev, { attribute_id, attribute_code }) => {
                const {
                    attribute_value,
                    attribute_id: attrId
                } = attributes[attribute_code] || {};

                if (attribute_value) {
                    return [
                        ...prev,
                        {
                            option_id: attribute_id || attrId,
                            option_value: attribute_value
                        }
                    ];
                }

                return prev;
            }, []);

        return { configurable_item_options };
    }

    if (type_id === BUNDLE && (productOptions || productOptionsMulti)) {
        return { bundle_options: Array.from(productOptions || []) };
    }

    if (type_id === SIMPLE && (productOptions || productOptionsMulti)) {
        return { customizable_options: productOptions || [], customizable_options_multi: productOptionsMulti || [] };
    }

    return {};
};


export const getConfigurableParametersFromFilters = (product, selectedFilters) => {
    const { variants = [] } = product;
    const filterKeys = Object.keys(selectedFilters);

    if (filterKeys.length < 0) {
        return { indexes: [], parameters: {} };
    }

    const indexes = getVariantsIndexes(variants, selectedFilters);
    const [index] = indexes;

    if (!variants[index]) {
        return { indexes: [], parameters: {} };
    }
    const { attributes } = variants[index];

    const parameters = Object.entries(attributes)
        .reduce((parameters, [key, { attribute_value }]) => {
            if (filterKeys.includes(key)) {
                return { ...parameters, [key]: attribute_value };
            }

            return parameters;
        }, {});

    return { indexes, index, parameters };
};

export const getCurrentVariantIndexFromFilters = (product, selectedFilters) => {
    if (!Object.keys(selectedFilters).length) {
        return -1;
    }

    const { index } = getConfigurableParametersFromFilters(product, selectedFilters);
    return index >= 0 ? index : -1;
};

const tip_produs = require('./tip_produs.json');

export const getAllowedFieldsFromProductType = (tipProdus) => {
    if (!tipProdus || !tipProdus.attribute_value || Object.keys(tipProdus.attribute_options).length === 0) {
        return [];
    }
    const { attribute_options, attribute_value: attribute_value_string } = tipProdus;

    const attribute_value = attribute_value_string.split(',')[0];

    if (!attribute_options[attribute_value]) {
        return [];
    }
    const tipProdusKey = attribute_options[attribute_value].label;

    if (!tip_produs[tipProdusKey]) {

        return { age: false, tip_ten: false, tip_par: false, sex: false };
    }

    return tip_produs[tipProdusKey];
};

/**
 * Rearrange products so that out-of-stock items appear last.
 * It preserves the original order among in-stock and out-of-stock items.
 *
 * @param {Array} products - List of products.
 * @returns {Array} Rearranged products.
 */
export const sortOutOfStockLast = (products = []) => {
    const inStockItems = products.filter(product => product.stock_status !== 'OUT_OF_STOCK');
    const outOfStockItems = products.filter(product => product.stock_status === 'OUT_OF_STOCK');
    return [...inStockItems, ...outOfStockItems];
};
