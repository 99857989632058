import {
    AFTER_ITEMS_TYPE,
    AppRouter as SourceAppRouter,
    BEFORE_ITEMS_TYPE,
    history,
    mapStateToProps,
    SWITCH_ITEMS_TYPE,
    withStoreRegex
} from 'SourceRoute';
import {lazy} from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';

import {updateMeta} from 'Store/Meta/Meta.action';
import {getStore} from 'Store';

import NotificationList from 'Component/NotificationList';
import DemoNotice from 'Component/DemoNotice';
import HeaderSticky from 'Component/HeaderSticky';
import NavigationTabs from 'Component/NavigationTabs';
import Breadcrumbs from 'Component/Breadcrumbs';
import NewsletterPopup from 'Component/NewsletterPopup';
import PromotionPopup from 'Component/PromotionPopup';
import LivePromo from 'Component/LivePromo';
import NewsletterSection from 'Component/NewsletterSection';
import InstaFeed from 'Component/InstaFeed';
import Footer from 'Component/Footer';
// import CookiePopup from 'Component/CookiePopup';

import GoogleTagManager from 'Component/GoogleTagManager';
import withGTM from 'Component/GoogleTagManager/withGTM.hoc';
import {
    BEAUTY_ADVENT_CALENDAR,
    BLACKFRIDAYTEASING_PAGE,
    CART,
    CHECKOUT,
    CMS_PAGE,
    CONFIRM_ACCOUNT,
    CUSTOMER_ACCOUNT,
    FORGOT_PASSWORD,
    GEROVITAL_KIDS_TEASING_PAGE,
    GIFT_CARD,
    HOME_PAGE,
    LOGIN,
    MENU,
    MIERCURI_PAGE,
    NEWSLETTER_PAGE,
    PASSWORD_CHANGE,
    PROMOTIONS,
    REGISTER,
    RETRY_PAYU_PAGE,
    SEARCH, TEASING_PAGE
} from 'Component/Header/Header.config';
import UrlRewrites from 'Route/UrlRewrites';
// start FA-1301 sentry init
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

export const HomePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/HomePage'));
export const SearchPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/SearchPage'));
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'));
export const CartPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cart" */ 'Route/CartPage'));
export const Checkout = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/Checkout'));
export const LoginPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/LoginPage'));
export const RegisterPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/RegisterPage'));
export const ForgotPasswordPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/ForgotPasswordPage'));
export const PasswordChangePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/PasswordChangePage'));
export const ConfirmAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/ConfirmAccountPage'));
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));
export const MenuPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/MenuPage'));
export const WishlistShared = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/WishlistSharedPage'));
export const PromotionPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */  'Route/PromotionPage'));
export const MiercuriPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */  'Route/MiercuriPage'));
export const NewsletterPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */  'Route/NewsletterPage'));
export const GiftCardPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */  'Route/GiftCardPage'));
export const TeasingPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */  'Route/TeasingPage'));
export const BeautyAdventCalendarPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */  'Route/BeautyAdventCalendarPage'));
export const RetryPayuPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */  'Route/RetryPayuPage'));
export const BlackFridayTeasingPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */  'Route/BlackFridayTeasingPage'));

export * from 'SourceRoute';

const CartDispatcher = import(/* webpackMode: "lazy", webpackChunkName: "dispatchers" */'Store/Cart/Cart.dispatcher');
const ConfigDispatcher = import(/* webpackMode: "lazy", webpackChunkName: "dispatchers" */'Store/Config/Config.dispatcher');
const WishlistDispatcher = import(/* webpackMode: "lazy", webpackChunkName: "dispatchers" */'Store/Wishlist/Wishlist.dispatcher');
const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
    );

export const mapDispatchToProps = dispatch => ({
    updateMeta: meta => dispatch(updateMeta(meta)),
    setWindowHeight: height => dispatch(setHeight(height)),
    setWindowWidth: width => dispatch(setWidth(width)),
    signInWithToken: (token) =>
        MyAccountDispatcher.then(({default: dispatcher}) =>
            dispatcher.signInWithToken(token, dispatch),
        ),
});

const SentryRoute = Sentry.withSentryRouting(Route);
const liveEnv = [
    'www.farmec.ro',
    'www.farmec.eu',
    'www.gerovital.it',
    'www.gerovital.eu'
];
const SOCIAL_LOGIN_REDIRECT_PATHS = ['checkout/shipping', 'my-account/dashboard'];

Sentry.init({
    dsn: "https://7a008095f1bd42c0bd31df0689f611e9@o1323315.ingest.sentry.io/6580962",
    normalizeDepth: 10,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
    ],
    environment: liveEnv.includes(window.location.host) ? 'production' : 'developer',
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
});

// end FA-1301 sentry init

export class AppRouter extends SourceAppRouter {
    componentWillMount() {
        // Initial setup
        if (!localStorage.getItem('lastAccessed')) {
            localStorage.setItem('lastAccessed', Date.now().toString());
        }
    }

    componentDidMount() {
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        window.addEventListener('storage', this.handleStorageEvent);
    }

    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        window.removeEventListener('storage', this.handleStorageEvent);
    }

    handleVisibilityChange = () => {
        try {
            if (document.visibilityState === 'visible') {
                this.checkElapsedTime();
            }
        } catch (e) {}
    }

    handleStorageEvent = (e) => {
        try {
            if (e.key === "forceRefresh" && e.newValue) {
                window.location.reload();
            }
        } catch (e) {}
    }

    checkElapsedTime = () => {
        const lastAccessed = localStorage.getItem('lastAccessed');
        const THREE_HOURS = 12 * 60 * 60 * 1000; // three hours in ms

        localStorage.setItem('lastAccessed', Date.now().toString());

        if (Date.now() - parseInt(lastAccessed, 10) > THREE_HOURS) {
            localStorage.setItem('forceRefresh', Date.now().toString()); // this will trigger a refresh in all tabs
            window.location.reload(); // refresh current tab
        }
    }

    dispatchActions() {
        const {
            isSignedIn,
            signInWithToken
        } = this.props;

        const {dispatch} = getStore();

        if (!isSignedIn) {
            const location = history.location || {};
            const search = location.search || '';
            const pathname = location.pathname || '';

            const urlParams = new URLSearchParams(search);
            const token = urlParams.get('token');

            const checkSocialLoginPath = (pathname, paths) => paths?.some(path => pathname.includes(path));
            const isSocialLoginRedirect = checkSocialLoginPath(pathname, SOCIAL_LOGIN_REDIRECT_PATHS);

            if (token && isSocialLoginRedirect) {
                try {
                    ConfigDispatcher.then(({default: dispatcher}) => dispatcher.handleData(dispatch));
                    signInWithToken(token);
                    return;
                } catch (e) {
                    throw e;
                }
            }
        }

        WishlistDispatcher.then(({default: dispatcher}) => dispatcher.updateInitialWishlistData(dispatch));
        CartDispatcher.then(({default: dispatcher}) => dispatcher.updateInitialCartData(dispatch));
        ConfigDispatcher.then(({default: dispatcher}) => dispatcher.handleData(dispatch));
    }

    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10
        },
        {
            component: <DemoNotice />,
            position: 15
        },
        {
            component: <HeaderSticky />,
            position: 20
        },
        {
            component: <NavigationTabs />,
            position: 30
        },
        {
            component: <Breadcrumbs />,
            position: 40
        },
        {
            component: <NewsletterPopup />,
            position: 55
        },
        {
            component: <GoogleTagManager />,
            position: 60
        },
        {
            component: <LivePromo />,
            position: 61
        },
        {
            component: <PromotionPopup />,
            position: 65
        }
    ];

    [SWITCH_ITEMS_TYPE] = [
        {
            component: <SentryRoute path={ withStoreRegex('/') } exact render={ withGTM(HomePage, HOME_PAGE) } />,
            position: 10
        },
        {
            component: <SentryRoute path={ withStoreRegex('/search/:query/') } render={ withGTM(SearchPage, SEARCH) } />,
            position: 25
        },
        {
            component: <SentryRoute path={ withStoreRegex('/page') } render={ withGTM(CmsPage, CMS_PAGE) } />,
            position: 40
        },
        {
            component: <SentryRoute path={ withStoreRegex('/cart') } exact render={ withGTM(CartPage, CART) } />,
            position: 50
        },
        {
            component: <SentryRoute path={ withStoreRegex('/checkout/:step?') } render={ withGTM(Checkout, CHECKOUT) } />,
            position: 55
        },
        {
            component: <SentryRoute path={ withStoreRegex('/:account*/login') } render={ withGTM(LoginPage, LOGIN) } />,
            position: 56
        },
        {
            component: <SentryRoute path={ withStoreRegex('/:account*/register') } render={ withGTM(RegisterPage, REGISTER) } />,
            position: 57
        },
        {
            component: <SentryRoute path={ withStoreRegex('/:account*/forgotPassword') } render={ withGTM(ForgotPasswordPage, FORGOT_PASSWORD) } />,
            position: 58
        },
        {
            component: <SentryRoute path={ withStoreRegex('/:account*/createPassword') } render={ withGTM(PasswordChangePage, PASSWORD_CHANGE) } />,
            position: 60
        },
        {
            component: <SentryRoute path={ withStoreRegex('/:account*/confirm') } render={ withGTM(ConfirmAccountPage, CONFIRM_ACCOUNT) } />,
            position: 65
        },
        {
            component: <SentryRoute path={ withStoreRegex('/my-account/:tab?') } render={ withGTM(MyAccount, CUSTOMER_ACCOUNT) } />,
            position: 70
        },
        {
            component: <SentryRoute path={ withStoreRegex('/forgot-password') } render={ withGTM(MyAccount, FORGOT_PASSWORD) } />,
            position: 71
        },
        {
            component: <SentryRoute path={ withStoreRegex('/menu') } render={ withGTM(MenuPage, MENU) } />,
            position: 80
        },
        {
            component: <SentryRoute path={ withStoreRegex('/wishlist/shared/:code') } component={ WishlistShared } />,
            position: 81
        },
        {
            component: <SentryRoute path={ withStoreRegex('/promo/:promotion?') } render={ withGTM(PromotionPage, PROMOTIONS) } />,
            position: 90
        },
        {
            component: <SentryRoute path={ withStoreRegex('/miercuri') } render={ withGTM(MiercuriPage, MIERCURI_PAGE) } />,
            position: 100
        },
        {
            component: <SentryRoute path={ withStoreRegex('/abonare-newsletter') } render={ withGTM(NewsletterPage, NEWSLETTER_PAGE) } />,
            position: 110
        },
        {
            component: <SentryRoute path={ withStoreRegex('/newsletter-subscription') } render={ withGTM(NewsletterPage, NEWSLETTER_PAGE) } />,
            position: 111
        },
        {
            component: <SentryRoute path={ withStoreRegex('/iscriviti-alla-newsletter') } render={ withGTM(NewsletterPage, NEWSLETTER_PAGE) } />,
            position: 112
        },
        {
            component: <SentryRoute path={ withStoreRegex('/card-cadou-farmec') } render={ withGTM(GiftCardPage, GIFT_CARD) } />,
            position: 120
        },
        {
            component: <SentryRoute path={ withStoreRegex('/avanpremiera-aslavital') } render={ withGTM(TeasingPage, TEASING_PAGE) } />,
            position: 130
        },
        {
            component: <SentryRoute path={ withStoreRegex('/teasing-black-friday') } render={ withGTM(TeasingPage, TEASING_PAGE) } />,
            position: 135
        },
        {
            component: <Route path={ withStoreRegex('/beauty-advent-calendar') } render={ withGTM(BeautyAdventCalendarPage, BEAUTY_ADVENT_CALENDAR) } />,
            position: 140
        },
        {
            component: <Route path={ withStoreRegex('/payu/payment/retry/ref_id/:id/reference/:order/ctrl/:code/') } render={ withGTM(RetryPayuPage, RETRY_PAYU_PAGE) } />,
            position: 990
        },
        {
            component: <SentryRoute component={ UrlRewrites } />,
            position: 1000
        }
    ];

    [AFTER_ITEMS_TYPE] = [
        {
            component: <NewsletterSection />,
            position: 1
        },
        {
            component: <InstaFeed />,
            position: 2
        },
        {
            component: <Footer />,
            position: 10
        },
        // {
        //     component: <CookiePopup />,
        //     position: 20
        // },
        // {
        //     component: <GeoIPPopup />,
        //     position: 30
        // }
    ];
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
