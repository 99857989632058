import {CUSTOMER, MyAccountDispatcher as SourceMyAccountDispatcher} from 'SourceStore/MyAccount/MyAccount.dispatcher';

import MyAccountQuery from 'Query/MyAccount.query';
import BrowserDatabase from 'Util/BrowserDatabase';
import {
    updateCustomerDetails,
    updateCustomerPasswordResetStatus,
    updateCustomerSignInStatus
} from 'Store/MyAccount/MyAccount.action';
import {fetchQuery, fetchMutation} from 'Util/Request';
import {showNotification} from 'Store/Notification/Notification.action';
import {deleteAuthorizationToken, setAuthorizationToken} from 'Util/Auth';
import {deleteGuestQuoteId} from 'Util/Cart';
import {ORDERS} from 'Store/Order/Order.reducer';
import {CART_TOTALS} from 'Store/Cart/Cart.reducer'
import {PAYMENT_TOTALS} from "Route/Checkout/Checkout.config";
import {CHECKOUT, MY_ACCOUNT, POPOVER_CLOSED_BY_USER} from 'Component/Header/Header.config';
import ProductHelper from 'Component/GoogleTagManager/utils';
import {history} from 'Route';
import Event, {EVENT_GTM_USER_LOGIN, EVENT_GTM_USER_REGISTER} from 'Util/Event';
import GoogleTagManager, {GROUPED_PRODUCTS_GUEST} from 'Component/GoogleTagManager/GoogleTagManager.component';

import {clearProductDetails} from 'Store/Product/Product.action';

export * from 'SourceStore/MyAccount/MyAccount.dispatcher';

const ONE_MONTH_IN_SECONDS = 2628000;
const CartDispatcher = import(/* webpackMode: "lazy", webpackChunkName: "dispatchers" */'Store/Cart/Cart.dispatcher');
const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    forceLogoutRedirectPages = [
        CHECKOUT,
        MY_ACCOUNT
    ];

    requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();

        const customer = BrowserDatabase.getItem(CUSTOMER) || {};
        if (customer.id) {
            dispatch(updateCustomerDetails(customer));
        }

        return fetchQuery(query).then(
            ({ customer }) => {
                dispatch(updateCustomerDetails(customer));
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
                this.transferGroupeProductsData(customer.id);
                GoogleTagManager.getInstance().updateGroupedProductsStorageName(customer.id);
            },
            (error) => dispatch(showNotification('error', error[0].message))
        );
    }

    async logout(authTokenExpired = false, dispatch) {
        if (authTokenExpired) {
            dispatch(showNotification('error', __('The session has expired. Please log in to your account again.')));
            this.handleForceRedirectToLoginPage();
        } else {
            deleteAuthorizationToken();
            dispatch(showNotification('success', __('You are successfully logged out!')));
        }

        await this.logoutCleanUp(dispatch);
    }

    async logoutOnSessionError(dispatch) {
        history.push('/account/login?sessionError=1');

        await this.logoutCleanUp(dispatch);
    }

    async logoutCleanUp(dispatch) {
        // new custom logout mutation
        const customer = BrowserDatabase.getItem(CUSTOMER) || {};
        const customerEmail = customer.email;
        await fetchMutation(MyAccountQuery.getLogOutMutation({email: customerEmail})).then(
            success => console.log(success),
            error => console.log(error)
        );

        deleteGuestQuoteId();
        BrowserDatabase.deleteItem(ORDERS);
        BrowserDatabase.deleteItem(CUSTOMER);
        BrowserDatabase.deleteItem(CART_TOTALS);
        BrowserDatabase.deleteItem(PAYMENT_TOTALS);

        BrowserDatabase.deleteItem(POPOVER_CLOSED_BY_USER)

        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateCustomerDetails({}));
        dispatch(clearProductDetails());

        CartDispatcher.then(
            ({default: dispatcher}) => dispatcher.updateInitialCartData(dispatch)
        );
        WishlistDispatcher.then(
            ({default: dispatcher}) => dispatcher.updateInitialWishlistData(dispatch)
        );
    }

    handleForceRedirectToLoginPage() {
        const { location: { pathname = '' } = {} } = history;
        const doRedirect = this.forceLogoutRedirectPages.reduce((result, urlPart) => {
            if (pathname.includes(urlPart)) {
                return true;
            }

            return result;
        }, false);

        if (doRedirect) {
            history.push({ pathname: '/account/login' });
        }
    }

    resetPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getResetPasswordMutation(options);

        return fetchMutation(mutation).then(
            ({ resetPassword: { status } }) => {
                dispatch(updateCustomerPasswordResetStatus(status));
                history.push({ pathname: '/account/login' });
            },
            () => dispatch(updateCustomerPasswordResetStatus('error'))
        );
    }

    createAccount(options = {}, dispatch) {
        const { customer: { email }, password } = options;
        const mutation = MyAccountQuery.getCreateAccountMutation(options);

        return fetchMutation(mutation).then(
            (data) => {
                const { createCustomer: { customer } } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    return 2;
                }

                Event.dispatch(EVENT_GTM_USER_REGISTER);
                return this.signIn({ email, password }, dispatch);
            },
            (error) => {
                dispatch(showNotification('error', error[0].message));
                Promise.reject();

                return false;
            }
        );
    }

    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);

        try {
            const result = await fetchMutation(mutation);
            const { generateCustomerToken: { token } } = result;

            setAuthorizationToken(token);
            BrowserDatabase.deleteItem('popoverClosedByUser')
            dispatch(updateCustomerSignInStatus(true));
            CartDispatcher.then(({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch));
            WishlistDispatcher.then(({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch));
            Event.dispatch(EVENT_GTM_USER_LOGIN);

            dispatch(clearProductDetails());

            return true;
        } catch (e) {
            throw e;
        }
    }

    signInWithToken(token, dispatch) {
        try {
            setAuthorizationToken(token);
            const query = MyAccountQuery.getCustomerQuery();

            fetchQuery(query).then((customer) => {
                    BrowserDatabase.deleteItem('popoverClosedByUser')
                    dispatch(updateCustomerSignInStatus(true));
                    CartDispatcher.then(({default: dispatcher}) => dispatcher.updateInitialCartData(dispatch));
                    WishlistDispatcher.then(({default: dispatcher}) => dispatcher.updateInitialWishlistData(dispatch));
                    Event.dispatch(EVENT_GTM_USER_LOGIN);

                    dispatch(clearProductDetails());
                    history.replace({search:''});
                    return true;
                }
            ).catch(error => {
                deleteAuthorizationToken();
                history.push({ pathname: '/account/login' });
            });
        } catch (e) {
            throw e;
        }
    }

    transferGroupeProductsData(id) {
        const GTMInstance = GoogleTagManager.getInstance();

        if (GTMInstance.groupedProductsStorageName !== GROUPED_PRODUCTS_GUEST) {
            return;
        }

        const guestGroupedProducts = GTMInstance.getGroupedProducts();
        GTMInstance.setGroupedProducts({});
        GTMInstance.updateGroupedProductsStorageName(id);

        const userGroupedProducts = GTMInstance.getGroupedProducts();
        const result = ProductHelper.mergeGroupedProducts(guestGroupedProducts, userGroupedProducts);

        GTMInstance.setGroupedProducts(result);
    }
}

export default new MyAccountDispatcher();
