import { MetaContainer as SourceMetaContainer } from 'SourceComponent/Meta/Meta.container';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isRoStore, isEuStore, isItStore } from 'Util/Store';

export const mapStateToProps = (state) => ({
    default_description: state.MetaReducer.default_description,
    default_keywords: state.MetaReducer.default_keywords,
    default_title: state.MetaReducer.default_title,
    canonical_url: state.MetaReducer.canonical_url,
    title_prefix: state.MetaReducer.title_prefix,
    title_suffix: state.MetaReducer.title_suffix,
    description: state.MetaReducer.description,
    keywords: state.MetaReducer.keywords,
    title: state.MetaReducer.title,
    robots: state.MetaReducer.robots,
    og_image: state.MetaReducer.og_image,
    og_type: state.MetaReducer.og_type,
    fallback_og_image: state.ConfigReducer.getOgImageUrl
});

export class MetaContainer extends SourceMetaContainer {
    static propTypes = {
        default_description: PropTypes.string,
        default_keywords: PropTypes.string,
        default_title: PropTypes.string,
        canonical_url: PropTypes.string,
        title_prefix: PropTypes.string,
        title_suffix: PropTypes.string,
        description: PropTypes.string,
        keywords: PropTypes.string,
        title: PropTypes.string,
        robots: PropTypes.string,
        og_image: PropTypes.string,
        og_type: PropTypes.string,
        fallback_og_image: PropTypes.string
    };

    static defaultProps = {
        default_description: '',
        default_keywords: '',
        default_title: '',
        canonical_url: '',
        title_prefix: '',
        title_suffix: '',
        description: '',
        keywords: '',
        title: '',
        robots: 'index, follow',
        og_image: '',
        og_type: '',
    };

    _getOgType() {
        const { og_type } = this.props;

        return og_type || null;
    }

    _getOgImage() {

        const { og_image, fallback_og_image } = this.props;
        return og_image || fallback_og_image || null;
    }

    _getRobots() {
        const { robots } = this.props;

        return robots;
    }


    _getHreflangTag() {
        let hreflang = '';
        let baseUrl = '';

        if (isRoStore()) {
            hreflang = 'ro';
            baseUrl = 'https://www.farmec.ro';
        } else if (isEuStore()) {
            hreflang = 'en';
            baseUrl = 'https://www.gerovital.eu';
        } else if (isItStore()) {
            hreflang = 'it';
            baseUrl = 'https://www.gerovital.it';
        }

        if (!hreflang || !baseUrl) {
            return null;
        }

        // Get full URL from canonical_url or fallback to window.location.href
        let { canonical_url } = this.props;
        let fullUrl = canonical_url && canonical_url.startsWith('http')
            ? canonical_url  // Use canonical_url if it's a full URL
            : window.location.href; // Otherwise, use the full browser URL

        // Ensure we are not appending local development URLs (like farmec.local)
        if (fullUrl.includes('farmec.local')) {
            fullUrl = fullUrl.replace(/https?:\/\/farmec\.local(:\d+)?/, '');
        }

        // Remove duplicated base URLs (if canonical_url already contains farmec.ro, etc.)
        if (fullUrl.startsWith(baseUrl)) {
            fullUrl = fullUrl.replace(baseUrl, '');
        }

        return {
            hreflang,
            href: `${baseUrl}${fullUrl}`
        };
    }


    _injectHreflangTag() {
        const hreflangData = this._getHreflangTag();
        if (!hreflangData) return;

        const { hreflang, href } = hreflangData;

        // Check if an existing hreflang link is present
        let existingLink = document.querySelector(`link[rel="alternate"][hreflang="${hreflang}"]`);

        if (existingLink) {
            // If the link exists, just update the href
            existingLink.href = href;
        } else {
            // Otherwise, create a new link
            const link = document.createElement('link');
            link.rel = 'alternate';
            link.hreflang = hreflang;
            link.href = href;
            document.head.appendChild(link);
        }
    }



    _getMetadata() {
        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            keywords: this._getKeywords(),
            robots: this._getRobots(),
            ['og:title']: this._getTitle(),
            ['og:description']: this._getDescription(),
            ['og:image']: this._getOgImage(),
            ['og:type']: this._getOgType()
        };

        this._injectHreflangTag();

        // Run both metadata generators
        const metaProperties = this._generateMetaFromMetadata(meta);
        const metaNames = this._generateMetaNameFromMetadata(meta);

        // Merge both metadata lists and return
        return [...metaProperties, ...metaNames];
    }

    _generateMetaFromMetadata(metadata, param = 'property') {
        return Object.entries(metadata).reduce((acc, [key, value]) => (
            value
                ? [...acc, { [param]: key, content: `${value}` }]
                : acc
        ), []);
    }
    _generateMetaNameFromMetadata(metadata, param = 'name') {
        return Object.entries(metadata).reduce((acc, [key, value]) => (
            value
                ? [...acc, { [param]: key, content: `${value}` }]
                : acc
        ), []);
    }
}

export default connect(mapStateToProps)(MetaContainer);
